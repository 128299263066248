import React, { useContext, useState, useEffect } from "react";
import {
  Button,
  Dialog,
  TextField,
  IconButton,
  Box,
  Tab,
  Tabs,
  CircularProgress,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import Tooltip from "@mui/material/Tooltip";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import {
  CloseOutlined,
  History,
  SupervisedUserCircle,
} from "@mui/icons-material";

import axios from "../../../../api/axios";
import qs from "query-string";
import DataContext from "../../../../../context/DataContext";
import ClientSearchMinimized from "../ClientSearchMinimized";
import CaseAssignment from "./CaseAssignment";
import ResponseModal from "../../../../global/ResponseModal";
import ClientInfo from "./ClientInfo";
import CaseInfoStatic from "./CaseInfoStatic";
import CaseSummary from "./CaseSummary";
import ClientProvidersv2 from "./ProvApptDetails/ClientProvidersv2";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  DesktopDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import ActionItemsTab from "./ActionItemsTab";
import DocumentUpload from "./DocumentManager/DocumentUpload";
import ViewDocuments from "./DocumentManager/DocumentUpload/ViewDocuments";
import { useDebouncedEffect } from "../../../../hooks/useDebounceEffect";
import AddCallbackButton from "./AddCallbackButton";
import { formatDistance } from "date-fns";
import ClientNotes from "./ClientNotes";
import TabClientNotesCallLog from "./TabClientNotesCallLog";
import PermissionWrapper from "../../../../global/PermissionWrapper";
import ApiRequestErrorHandler from "../../../../global/ApiRequestErrorHandler";
import ContactControlsMobile from "../../../../global/ContactControlsMobile";
import Injuries from "./Injuries";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ClientDetail() {
  const {
    tabValue,
    setTabValue,
    caseManagerPk,
    accessToken,
    trigger,
    setTrigger,
    userRoles,
    userType,
    loggedInUser,
    setSelectedClient,
  } = useContext(DataContext);

  const [caseName, setCaseName] = useState();
  const [editCaseName, setEditCaseName] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorArray, setErrorArray] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [clientPM, setClientPM] = useState(null);
  const [caseObj, setCaseObj] = useState();
  const [clientLawfirm, setClientLawfirm] = useState("");
  const [uploadDocument, setUploadDocument] = useState(false);
  const [viewDocuments, setViewDocuments] = useState(false);
  const [clientDocs, setClientDocs] = useState("");
  const [clientImages, setClientImages] = useState("");
  const [viewImage, setViewImage] = useState(false);
  const [activeImage, setActiveImage] = useState("");
  const [openLfDetails, setOpenLfDetails] = useState(false);
  const [caseSources, setCaseSources] = useState();
  const [editUmStatus, setEditUmStatus] = useState(false);
  const [pdVal, setPdVal] = useState();
  const [callback, setCallback] = useState(new Date());
  const [callBackReason, setCallBackReason] = useState("");
  const [docResLimit, setDocResLimit] = useState(10);
  const [showMoreDocsVisible, setShowMoreDocsVisible] = useState(false);
  const [statusEdit, setStatusEdit] = useState(false);
  const [imgResLimit, setImgResLimit] = useState(5);
  const [showMoreImgsVisible, setShowMoreImgsVisible] = useState(false);
  const [openCbModal, setOpenCbModal] = useState(false);
  const [activeProvObj, setActiveProvObj] = useState({});
  const [documentTypeObj, setDocumentTypeObj] = useState();
  const [docTrigger, setDocTrigger] = useState(false);
  const [searchDocs, setSearchDocs] = useState("");
  const [docLoading, setDocLoading] = useState(false);
  const [docNeedsType, setDocNeedsType] = useState();
  const [languageOptions, setLanguageOptions] = useState(null);
  const [languageObj, setLanguageObj] = useState("");

  const navigate = useNavigate();
  const params = useParams();

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const navigateToRelatedDetail = (pk) => {
    navigate(`/client-detail/${pk}`);
  };

  const closeUploadModal = () => {
    setUploadDocument(false);
  };

  const openImageModal = (img) => {
    setActiveImage(img);
    setViewImage(true);
  };

  const closeImageModal = () => {
    setActiveImage("");
    setViewImage(false);
  };

  const handleChange = (event, newValue) => {
    //keep event param even though unused
    setTabValue(newValue);
  };

  const handleApiError = (error) => {
    const res = error.response.data;
    const errArr = ApiRequestErrorHandler(res);
    setErrorArray(errArr);
    setOpenError(!openError);
  };

  const truncate = (str, max, len) => {
    if (!str) {
      return;
    }
    return str.length > max ? str.substring(0, len) + "..." : str;
  };

  const handleName = () => {
    let patchedData = {
      ...(caseName === "" ? {} : { name: caseName }),
    };

    axios
      .patch(`/api/case/${caseObj.pk}/`, patchedData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        if (response.status < 405) {
          setOpenSuccess(!openSuccess);
          setEditCaseName(false);
        }
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
        }
        setOpenError(!openError);
      });
  };

  const handleCaseStatusSubmit = (e) => {
    let patchedData;
    let caseStatusVal = e.target.value;
    if (caseStatusVal === "Disbursed") {
      patchedData = {
        ...(caseStatusVal === "" ? {} : { status: caseStatusVal }),
        archived: true,
        settled: true,
        progression_finished: true,
        all_medical_docs_recieved: true,
        all_treatments_complete: true,
        introduced_to_np: true,
        onboarded: true,
        lost: null,
      };
    } else if (
      caseStatusVal === "Progression Complete" ||
      caseStatusVal === "Waiting on Offer"
    ) {
      patchedData = {
        ...(caseStatusVal === "" ? {} : { status: caseStatusVal }),
        archived: false,
        progression_finished: true,
        all_medical_docs_recieved: true,
        all_treatments_complete: true,
        introduced_to_np: true,
        settled: false,
        onboarded: true,
        lost: null,
      };
    } else if (caseStatusVal === "No Case") {
      patchedData = {
        ...(caseStatusVal === "" ? {} : { status: caseStatusVal }),
        archived: true,
      };
    } else if (caseStatusVal === "Waiting on Medical") {
      patchedData = {
        ...(caseStatusVal === "" ? {} : { status: caseStatusVal }),
        archived: false,
        all_medical_docs_recieved: false,
        settled: false,
        progression_finished: false,
        introduced_to_np: true,
        onboarded: true,
        lost: null,
      };
    } else if (caseStatusVal === "Treating") {
      patchedData = {
        ...(caseStatusVal === "" ? {} : { status: caseStatusVal }),
        archived: false,
        all_treatments_complete: false,
        introduced_to_np: true,
        progression_finished: false,
        onboarded: true,
        lost: null,
      };
    } else if (caseStatusVal === "Intake") {
      patchedData = {
        ...(caseStatusVal === "" ? {} : { status: caseStatusVal }),
        introduced_to_np: false,
        settled: false,
        all_treatments_complete: false,
        onboarded: false,
        lost: null,
        archived: false,
      };
    } else if (
      caseStatusVal === "Settled" ||
      caseStatusVal === "Sent to Trial Attorney"
    ) {
      patchedData = {
        ...(caseStatusVal === "" ? {} : { status: caseStatusVal }),
        archived: false,
        settled: true,
        progression_finished: true,
        introduced_to_np: true,
        onboarded: true,
        lost: null,
      };
    } else {
      patchedData = {
        ...(caseStatusVal === "" ? {} : { status: caseStatusVal }),
        archived: false,
      };
    }

    axios
      .patch(`/api/case/${caseObj.pk}/`, patchedData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        if (response.status < 405) {
          setOpenSuccess(!openSuccess);
          setStatusEdit(false);
          setTrigger(!trigger);
        }
      })
      .catch(function (error) {
        handleApiError(error);
      });
  };

  const handleUmStatusSubmit = (e) => {
    let umStatusVal = { um_status: e.target.value };
    axios
      .patch(`/api/case/${caseObj.pk}/`, umStatusVal, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        if (response.status < 405) {
          setOpenSuccess(!openSuccess);
          setEditUmStatus(false);
          setTrigger(!trigger);
        }
      })
      .catch(function (error) {
        handleApiError(error);
      });
  };

  //**Main Case Query */
  useEffect(() => {
    setSelectedClient(params.id);

    if (!params) {
      return;
    } else {
      axios
        .get(`/api/case/${params.id}/`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          const caseResponse = response.data;
          setCaseObj(caseResponse);
          setCallback(caseResponse.callback_time);
          setCaseName(
            !caseResponse.name || caseResponse.name === ""
              ? ""
              : caseResponse.name
          );
          setClientPM(
            !caseResponse.client_manager || caseResponse.client_manager === ""
              ? ""
              : caseResponse.client_manager
          );
        })
        .catch((response) => {
          navigate("/dashboard");
          console.log(response);
        });
    }
    // eslint-disable-next-line
  }, [accessToken, trigger, params, userRoles.permissions, setSelectedClient]);

  useEffect(() => {
    let active = true;

    if (active) {
      if (userRoles.permissions.includes("utilities.view_language")) {
        axios
          .get(`/api/utilities/language/?ordering=created_at`, {
            headers: { Authorization: `Token ${accessToken}` },
          })
          .then((response) => {
            let data = response.data.results;
            setLanguageOptions(data);

            let obj = {};

            for (const item in data) {
              obj[data[item].pk] = data[item];
            }
            setLanguageObj(obj);
          })
          .catch(function (error) {
            if (error.response) {
              console.log(error.response);
            }
          });
      }
    }
    return () => {
      active = false;
    };
  }, [accessToken, setLanguageOptions, userRoles.permissions, setLanguageObj]);

  useEffect(() => {
    axios
      .options(`/api/documents/`, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then((response) => {
        let data = response.data.actions.POST.document_type.choices;
        let obj = {};
        for (const item in data) {
          obj[data[item].value] = data[item].display_name;
        }

        setDocumentTypeObj(obj);
      })
      .catch((response) => {
        console.log(response);
      });
  }, [setDocumentTypeObj, accessToken]);

  useDebouncedEffect(
    () => {
      if (!params) {
        return;
      } else if (userRoles.permissions.includes("filemanager.view_document")) {
        setDocLoading(true);
        let query = qs.stringify(
          {
            search: !searchDocs ? null : searchDocs,
          },
          {
            skipNull: true,
          }
        );

        axios
          .get(
            `/api/documents/?limit=${docResLimit}&case=${params.id}&${query}`,
            {
              headers: { Authorization: `Token ${accessToken}` },
            }
          )
          .then((response) => {
            let data = response.data.results;
            if (response.data.count <= docResLimit) {
              setShowMoreDocsVisible(false);
            } else {
              setShowMoreDocsVisible(true);
            }
            setClientDocs(data);
            setDocLoading(false);
          })
          .catch((response) => {
            console.log(response);
          });
      } else {
        return;
      }
    },
    [
      caseObj,
      docResLimit,
      accessToken,
      docTrigger,
      params,
      userRoles.permissions,
      searchDocs,
    ],
    250
  );

  useEffect(() => {
    if (
      !params ||
      !userRoles.permissions.includes("filemanager.view_document") ||
      !caseObj
    ) {
      return;
    } else {
      axios
        .get(
          `/api/documents/?limit=${imgResLimit}&case=${caseObj.pk}&document_type__in=8,11,25,10,13,14`,
          {
            headers: { Authorization: `Token ${accessToken}` },
          }
        )
        .then((response) => {
          let data = response.data.results;
          if (response.data.count <= imgResLimit) {
            setShowMoreImgsVisible(false);
          } else {
            setShowMoreImgsVisible(true);
          }
          setClientImages(data);
        })
        .catch((response) => {
          console.log(response);
        });
    }
  }, [
    caseObj,
    imgResLimit,
    accessToken,
    docTrigger,
    userRoles.permissions,
    params,
  ]);

  useEffect(() => {
    if (
      !params ||
      !userRoles.permissions.includes("filemanager.view_document") ||
      !caseObj
    ) {
      return;
    } else {
      axios
        .get(`/api/case/${caseObj.pk}/missing-docs/`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          let data = response.data;
          setDocNeedsType(data);
        });
    }
  }, [
    accessToken,
    userRoles.permissions,
    caseObj,
    setDocNeedsType,
    docTrigger,
    params,
  ]);

  useEffect(() => {
    if (
      userRoles.permissions.includes("leads.view_campaign") ||
      userRoles.permissions.includes("leads.view_all_campaigns")
    ) {
      axios
        .get(`/api/leads/campaigns/?limit=100`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          let data = response.data.results;
          let sourceObj = {};

          for (const item in data) {
            sourceObj[data[item].pk] = data[item].name;
          }

          setCaseSources(sourceObj);
        });
    }
  }, [setCaseSources, accessToken, userRoles.permissions]);

  useEffect(() => {
    if (
      userRoles.permissions.includes("provider.view_provider") &&
      userRoles.permissions.includes("users.view_usertoproviderassignments")
    ) {
      let managedObj = {};
      let utpObj = {};
      axios
        .get(`/api/provider/?managed_by=${caseManagerPk}&simplified=true?`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          let data = response.data.results;

          for (const item in data) {
            managedObj[data[item].pk] = data[item].pk;
          }
        });

      axios
        .get(`/api/user_provider/?user=${caseManagerPk}&simplified=true?`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          let data = response.data.results;

          for (const item in data) {
            utpObj[data[item].provider.id] = data[item].provider.id;
          }
        });

      setTimeout(() => {
        setActiveProvObj({ ...managedObj, ...utpObj });
      }, "1000");
    } else {
      return;
    }
  }, [accessToken, caseManagerPk, userRoles.permissions]);

  useEffect(() => {
    if (
      openLfDetails &&
      userRoles.permissions.includes("lawyer.view_all_law_firms") &&
      caseObj.law_firm
    ) {
      axios
        .get(`/api/lawfirm/${caseObj.law_firm}/`, {
          headers: { Authorization: `Token ${accessToken}` },
        })
        .then((response) => {
          let data = response.data;
          setClientLawfirm(data);
        })
        .catch((response) => {
          console.log(response);
        });
    } else {
      return;
    }
  }, [openLfDetails, userRoles.permissions, caseObj, accessToken]);

  const addCallback = (type) => {
    let patchedData;
    if (type === "reset") {
      patchedData = {
        callback_time: null,
        callback_reason: null,
      };
    } else {
      patchedData = {
        callback_time: callback,
        callback_reason: callBackReason,
      };
    }

    axios
      .patch(`/api/case/${caseObj.pk}/`, patchedData, {
        headers: { Authorization: `Token ${accessToken}` },
      })
      .then(function (response) {
        if (response.status < 405) {
          setTrigger(!trigger);
          setOpenCbModal(false);
          setOpenSuccess(true);
        }
      })
      .catch(function (error) {
        handleApiError(error);
      });
  };
  //! This needs to stay at the very end of the list of functions
  const generateDetailTabs = () => {
    let detailComps = [];
    if (userRoles.permissions.includes("casemanager.view_case")) {
      detailComps.push({
        tabTitle: "Case Summary",
        order: 6,
        component: (
          <CaseSummary
            caseName={caseName}
            clientPM={clientPM}
            caseInformation={caseObj}
            handleCaseStatusSubmit={handleCaseStatusSubmit}
            statusEdit={statusEdit}
            setStatusEdit={setStatusEdit}
            editCaseName={editCaseName}
            setEditCaseName={setEditCaseName}
            setCaseName={setCaseName}
            handleName={handleName}
            caseSources={caseSources}
            clientLawfirm={clientLawfirm}
            setEditUmStatus={setEditUmStatus}
            editUmStatus={editUmStatus}
            handleUmStatusSubmit={handleUmStatusSubmit}
            canEdit={
              userRoles.permissions.includes("casemanager.change_case")
                ? true
                : false
            }
          />
        ),
      });
    }
    if (
      userRoles.permissions.includes("filemanager.view_document") ||
      userRoles.permissions.includes("users.view_user")
    ) {
      detailComps.push({
        tabTitle: "Client Info",
        order: 1,
        component: (
          <ClientInfo
            images={clientImages}
            uploadDocument={uploadDocument}
            setUploadDocument={setUploadDocument}
            openImageModal={openImageModal}
            caseInfo={caseObj}
            showMoreImgsVisible={showMoreImgsVisible}
            setShowMoreImgsVisible={setShowMoreImgsVisible}
            imgResLimit={imgResLimit}
            setImgResLimit={setImgResLimit}
            // pdChoices={pdChoices}
            pdVal={pdVal}
            setPdVal={setPdVal}
            docNeedsType={docNeedsType}
            setViewDocuments={setViewDocuments}
            languageOptions={languageOptions}
            languageObj={languageObj}
          />
        ),
      });
    }
    if (userRoles.permissions.includes("casemanager.view_actionableitems")) {
      detailComps.push({
        tabTitle: "Actionable Items",
        order: 2,
        component: <ActionItemsTab caseObj={caseObj} />,
      });
    }

    if (
      userRoles.permissions.includes("provider.view_providerclientstatus") ||
      userRoles.permissions.includes(
        "provider.view_provider_client_statuses_related_to_treating_clients"
      ) ||
      userRoles.permissions.includes(
        "provider.view_provider_client_statuses_related_to_assigned_clients"
      )
    ) {
      detailComps.push({
        tabTitle: "Providers",
        order: 3,
        component: (
          <ClientProvidersv2
            isAppointments={false}
            caseInfo={caseObj}
            // providerAmountObj={providerAmountObj}
            activeProvObj={activeProvObj}
          />
        ),
      });
    }
    if (userRoles.permissions.includes("provider.view_appointment")) {
      detailComps.push({
        tabTitle: "Appointments",
        order: 4,
        component: (
          <ClientProvidersv2
            isAppointments={true}
            caseInfo={caseObj}
            activeProvObj={activeProvObj}
          />
        ),
      });
    }
    if (userRoles.permissions.includes("casemanager.view_casereview")) {
      detailComps.push({
        tabTitle: "Injuries",
        order: 5,
        component: <Injuries caseObj={caseObj} />,
      });
    }
    return detailComps;
  };

  const DetailTabs = generateDetailTabs();

  if (params.id !== "null") {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <p className="text-[12px] text-gray-700 text-normal text-right mr-2">
          {!caseObj || userType !== "Client Case Management" ? (
            ""
          ) : (
            <>
              <Tooltip title={"Case Last Updated"}>
                <History />
              </Tooltip>
              &nbsp;
              <span>
                {new Date(caseObj.updated_at).toLocaleString("en-US", {
                  dateStyle: "short",
                  timeStyle: "short",
                })}
                &nbsp;(
                {formatDistance(new Date(caseObj.updated_at), new Date())})
              </span>
            </>
          )}
        </p>
        <div className="w-full h-full overflow-y-scroll mb-4 pb-6 flex lg:flex-row flex-col">
          <div
            className={`pl-2 ${
              userRoles.permissions.includes("casemanager.view_casenotes")
                ? "lg:w-[60%]"
                : "w-full"
            }`}
          >
            <div className="flex items-center xl:flex-row flex-col relative">
              <div className="xl:w-1/3 w-full">
                <ClientSearchMinimized />
              </div>
              <div className="xl:w-2/3 w-full">
                <div>
                  {" "}
                  <div className="flex items-center justify-center">
                    <div className="mr-2">
                      {!caseObj ? (
                        ""
                      ) : loggedInUser.entity &&
                        loggedInUser.entity.entity_type_label ===
                          "Client Case Management" &&
                        caseObj.entity ? (
                        <div className="text-center font-bold flex items-center justify-center text-[14px]">
                          <Tooltip title="Entity">
                            <SupervisedUserCircle className="text-[18px]" />
                          </Tooltip>
                          <p>
                            {!caseObj
                              ? ""
                              : !caseObj.entity
                              ? ""
                              : caseObj.entity.name}{" "}
                            -
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div>
                      <IconButton
                        className="w-[20px] mr-2"
                        onClick={() => setOpenLfDetails(true)}
                        disabled={
                          userRoles.permissions.includes(
                            "lawyer.view_all_law_firms"
                          ) &&
                          caseObj &&
                          caseObj.law_firm
                            ? false
                            : true
                        }
                      >
                        {" "}
                        <Tooltip title="Open Law Firm Details">
                          <AccountBalanceIcon className="text-black" />
                        </Tooltip>
                      </IconButton>
                      {!caseObj
                        ? ""
                        : !caseObj.law_firm_name
                        ? "No Law Firm Assigned"
                        : caseObj.law_firm_name}
                    </div>
                  </div>
                </div>
                {userRoles.permissions.includes(
                  "users.view_usertocaseassignments"
                ) ? (
                  <CaseAssignment
                    currentClient={!caseObj ? "" : caseObj.pk}
                    caseObj={caseObj}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
            <div>
              <CaseInfoStatic
                caseInfo={caseObj}
                formatter={formatter}
                selectedClient={params.id}
                setViewDocuments={setViewDocuments}
                caseSources={caseSources}
                truncate={truncate}
                navigateToRelatedDetail={navigateToRelatedDetail}
                setUploadDocument={setUploadDocument}
                docNeedsType={docNeedsType}
                languageObj={languageObj}
              />
            </div>
            <div>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  padding: "0",
                }}
              >
                <Tabs
                  value={tabValue}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  className="justify-between "
                  variant="fullWidth"
                  TabIndicatorProps={{ sx: { backgroundColor: "#9333EA" } }}
                  sx={{
                    "& button.Mui-selected": {
                      color: `#9333EA`,
                    },
                    ".Mui-selected": {
                      color: `#9333EA`,
                    },
                    ".MuiBox-root.div": {
                      padding: 0,
                    },
                  }}
                >
                  {DetailTabs.sort((a, b) => a.order - b.order).map(
                    (tab, idx) => (
                      <Tab
                        key={idx}
                        label={tab.tabTitle}
                        {...a11yProps(idx)}
                        className={
                          DetailTabs.length <= 1
                            ? "w-full"
                            : `w-1/${DetailTabs.length}`
                        }
                      />
                    )
                  )}
                </Tabs>
              </Box>
              {DetailTabs.sort((a, b) => a.order - b.order).map((tab, idx) => (
                <TabPanel value={tabValue} index={idx} key={idx}>
                  <div className="mb-4 rounded-md  shadow-lg mt-5">
                    {tab.component}
                  </div>
                </TabPanel>
              ))}
            </div>
          </div>
          {userRoles.permissions.includes("casemanager.view_casenotes") ? (
            <div className="lg:w-[40%] w-full mt-3">
              <AddCallbackButton
                caseObj={caseObj}
                onclick={() => setOpenCbModal(!openCbModal)}
                canEdit={userRoles.permissions.includes(
                  "casemanager.change_case"
                )}
                note={
                  userRoles.permissions.includes("casemanager.change_case")
                    ? "Add/Edit Callback"
                    : "You do not have permissions to edit this content"
                }
              />
              <div className="mx-2 w-full">
                <PermissionWrapper
                  permission={
                    !userRoles.permissions.includes("call_log.view_calllog")
                  }
                >
                  <ClientNotes
                    caseObj={!caseObj ? "" : caseObj}
                    clientLawfirm={clientLawfirm}
                    params={params}
                  />
                </PermissionWrapper>
                <PermissionWrapper
                  permission={userRoles.permissions.includes(
                    "call_log.view_calllog"
                  )}
                >
                  <TabClientNotesCallLog
                    caseObj={caseObj}
                    clientLawfirm={clientLawfirm}
                    params={params}
                  />
                </PermissionWrapper>
              </div>
            </div>
          ) : (
            ""
          )}
          <ResponseModal
            title="Case Info"
            description="Case Information Updated"
            openBool={openSuccess}
            setOpenBool={setOpenSuccess}
          />
          <Dialog open={openLfDetails} fullWidth>
            <div
              style={{
                padding: 20,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                  fontSize: "20px",
                }}
              >
                <Button onClick={() => setOpenLfDetails(false)}>
                  <CloseOutlined />
                </Button>
              </div>
              <h1
                style={{
                  fontSize: "24px",
                  margin: "0 0 1rem 0",
                  textAlign: "left",
                  fontWeight: "bold",
                  color: "#1976d2",
                }}
              >
                Law Firm Detail
              </h1>
              {!clientLawfirm ? (
                <CircularProgress color="secondary" />
              ) : (
                <div
                  className="flex rounded-b-lg"
                  style={{ padding: "0 0 2rem 0" }}
                >
                  <div style={{ textAlign: "left" }}>
                    <h3 className="my-2">
                      <strong>Name:</strong> {clientLawfirm.name}
                    </h3>
                    <h3 className="my-2">
                      <strong>Address:</strong>{" "}
                      {!clientLawfirm.address ? "" : clientLawfirm.address.raw}
                    </h3>
                    <h3 className="my-2">
                      <strong>Email:</strong>{" "}
                      <a
                        href={`mailto:${clientLawfirm.email}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {clientLawfirm.email}
                      </a>
                    </h3>
                    <h3 className="my-2">
                      <strong>Lawfirm Website:</strong>{" "}
                      <a
                        href={clientLawfirm.homepage_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {clientLawfirm.homepage_url}
                      </a>
                    </h3>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "1rem",
                      }}
                    >
                      <h3>
                        <strong>Phone:</strong> {clientLawfirm.phone}&nbsp;
                      </h3>
                      <ContactControlsMobile number={clientLawfirm.phone} />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Dialog>
          <DocumentUpload
            closeUploadModal={closeUploadModal}
            casePk={!caseObj ? "" : caseObj.pk}
            clientId={caseObj?.client?.pk}
            uploadDocument={uploadDocument}
            setUploadDocument={setUploadDocument}
            activeProvObj={activeProvObj}
            docTrigger={docTrigger}
            setDocTrigger={setDocTrigger}
            setViewDocuments={setViewDocuments}
            docNeedsType={docNeedsType}
          />
          <ViewDocuments
            documents={clientDocs}
            viewDocuments={viewDocuments}
            setViewDocuments={setViewDocuments}
            documentTypeObj={documentTypeObj}
            truncate={truncate}
            docTrigger={docTrigger}
            setDocTrigger={setDocTrigger}
            docResLimit={docResLimit}
            setDocResLimit={setDocResLimit}
            showMoreDocsVisible={showMoreDocsVisible}
            setShowMoreDocsVisible={setShowMoreDocsVisible}
            searchDocs={searchDocs}
            setSearchDocs={setSearchDocs}
            docLoading={docLoading}
            docNeedsType={docNeedsType}
            clientId={caseObj?.client?.pk}
            activeProvObj={activeProvObj}
          />
          <Dialog open={viewImage}>
            <div style={{ padding: "2rem" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "0 0 1rem",
                }}
              >
                <IconButton onClick={() => closeImageModal()}>
                  <CloseOutlined />
                </IconButton>
              </div>
              <Tooltip title="Download Image">
                <a
                  download={activeImage.desc}
                  href={activeImage.image}
                  title={activeImage.desc}
                  target="_blank44"
                >
                  <img alt={activeImage.desc} src={activeImage.image} />
                </a>
              </Tooltip>
            </div>
          </Dialog>
          <Dialog open={openCbModal} fullWidth sx={{ top: "25%" }}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {" "}
              <Button onClick={() => setOpenCbModal(false)}>
                <CloseOutlined />
              </Button>
            </div>
            <div style={{ padding: "1rem" }}>
              <p style={{ textAlign: "center", margin: "0 0 1rem" }}>
                Schedule Callback
              </p>
              <DesktopDateTimePicker
                label="Callback Date/Time"
                value={!callback ? new Date() : new Date(callback)}
                className="w-full my-4 mx-auto text-black"
                onChange={(newValue) => setCallback(newValue)}
                slotProps={{ textField: { variant: "outlined" } }}
              />
              <div style={{ margin: "1rem 0 0" }}>
                <TextField
                  className="w-full my-4 text-center"
                  label="Change Callback Reason/Description"
                  style={{ width: "100%" }}
                  onChange={(e) => setCallBackReason(e.target.value)}
                  value={callBackReason}
                />
              </div>
            </div>
            <div style={{ width: "fit-content", margin: "0 auto" }}>
              <Button
                onClick={() => addCallback("reset")}
                style={{ color: "red" }}
              >
                Clear
              </Button>
              <Button onClick={() => addCallback("")}>Submit</Button>
            </div>
          </Dialog>
        </div>
        <ResponseModal
          title="Success"
          description="The case content was updated"
          openBool={openSuccess}
          setOpenBool={setOpenSuccess}
        />
        <ResponseModal
          title="Case update failed"
          isError={true}
          openBool={openError}
          setOpenBool={setOpenError}
          errorMessage={errorArray}
        />
      </LocalizationProvider>
    );
  } else {
    return (
      <div className="h-1/2 w-full text-center rounded-lg text-2xl mt-20 hover:bg-gray-200">
        <br />
        Please Select a Client to View Client's Details...
      </div>
    );
  }
}
